<template>
  <div id="approveDetails">
    <bhNavBar title="审批详情"></bhNavBar>
    <div v-show="isDown !== '' || !isFreelance !== ''">
      <!-- 不是下包-->
      <div v-if="!isDown && !isFreelance">
        <div class="title">候选人信息</div>
        <div class="review">
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">候选人：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.name }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">客户报价：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.inputOutsourcing }} 元/
              {{ posOfferExtension.positionOfferExtension.inputOutsourcingType.substring(0, posOfferExtension.positionOfferExtension.inputOutsourcingType.length - 2) }}
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">利润率：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.profitMargin, 2) }}%</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">社保+公积金基数：</span>
              <span class="normalFont">{{
                  posOfferExtension.positionOfferExtension.socialInsuranceBase
                }} 元 + {{ posOfferExtension.positionOfferExtension.providentFundBase }} 元 </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">月商务费：</span>
              <span class="normalFont">
             <input type="number" min="0" placeholder="请输入月商务费" :disabled="editType!=0"
                    @change="handleChange($event,[4,5,6,8,9])"
                    v-model="posOfferExtension.positionOfferExtension.inputBusinessExpenses"/>
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around" v-if="posOfferExtension.positionOfferExtension.remarks">
            <van-col span="24">
              <span class="boldFont">特殊事项备注：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.remarks }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around" v-if="posOfferExtension.positionOfferExtension.isAffiliated">
            <van-col span="24">
              <span class="boldFont">公积金比例(%)：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.providentFundCompanyPercentage }}</span>
            </van-col>
          </van-row>
          </div>
      </div>
      <!-- 是下包-->
      <div v-if="isDown">
        <div class="title">下包候选人信息</div>
        <div class="review">
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">候选人：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.name }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">客户报价：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.inputOutsourcing }} 元/
              {{ posOfferExtension.positionOfferExtension.inputOutsourcingType.substring(0, posOfferExtension.positionOfferExtension.inputOutsourcingType.length - 2) }}
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">利润率：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.profitMargin, 2) }}%</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">下包成本：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.inputThirteenSalaryBase }} 元/
              {{ posOfferExtension.positionOfferExtension.inputThirteenSalaryBaseType.substring(0, posOfferExtension.positionOfferExtension.inputThirteenSalaryBaseType.length - 2) }}
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">增值税：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.taxRate }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around" >
            <van-col span="24">
              <span class="boldFont">月商务费：</span>
              <span class="normalFont">
             <input type="number" min="0" placeholder="请输入月商务费" :disabled="editType!=0"
                    @change="handleChange($event,[4,5,6,8,9])"
                    v-model="posOfferExtension.positionOfferExtension.inputBusinessExpenses"/>
          </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">其他：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.other }}元</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- 是freelance-->
      <div v-if="isFreelance">
        <div class="title">freelance候选人信息</div>
        <div class="review">
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">候选人：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.name }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">客户报价(人天)：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.inputOutsourcing }} 元/日
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">客户报价税点：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.dayOutsourcingRax }} 元
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">日薪：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.thirteenSalaryBase }} 元/日
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">日薪税点：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.thirteenSalaryBaseRax }} 元
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">其他：</span>
              <span class="normalFont">
              {{ posOfferExtension.positionOfferExtension.other }} 元
            </span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around" >
            <van-col span="24">
              <span class="boldFont">商务费：</span>
              <span class="normalFont">
                <input type="number" min="0" placeholder="请输入商务费" :disabled="editType!=0"
                  @change="handleDayBaseChange($event,[2, 3])"
                  v-model="posOfferExtension.positionOfferExtension.inputBusinessExpenses"/>
             </span>
              </van-col>
            </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">利润率：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.profitMargin, 2) }}%</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around" v-if="posOfferExtension.positionOfferExtension.remarks">
            <van-col span="24">
              <span class="boldFont">特殊事项备注：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.remarks }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div v-if="!isDown && !isFreelance">
        <div class="title">录用信息</div>
        <div class="review">
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">录用主体：</span>
              <span class="normalFont">{{ getCompany() }}</span>
            </van-col>
          </van-row>
          <!--        <van-row class="vanRowStyle" type="flex" justify="space-around">-->
          <!--          <van-col span="24">-->
          <!--            <span class="boldFont">客户：</span>-->
          <!--            <span class="normalFont">{{ getCompany() }}</span>-->
          <!--          </van-col>-->
          <!--        </van-row>-->
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">职位：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.position }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">项目：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.project }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">合同期限：</span>
              <span class="normalFont">{{ parseTime(posOfferExtension.positionOfferExtension.contractPeriodStart) }}至{{ posOfferExtension.positionOfferExtension.contractPeriodEnd }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">试用期：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.probationPeriod }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">试用期工资：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.probationPeriodMonthSalary, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">转正工资：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.thirteenSalaryBase, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">基本工资：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.monthBaseSalary, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">岗位工资：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.monthPostSalary, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">其他拟约定：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.otherRamks }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div v-if="isFreelance">
        <div class="title">录用信息</div>
        <div class="review">
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">录用主体：</span>
              <span class="normalFont">{{ getCompany() }}</span>
            </van-col>
          </van-row>
          <!--        <van-row class="vanRowStyle" type="flex" justify="space-around">-->
          <!--          <van-col span="24">-->
          <!--            <span class="boldFont">客户：</span>-->
          <!--            <span class="normalFont">{{ getCompany() }}</span>-->
          <!--          </van-col>-->
          <!--        </van-row>-->
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">职位：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.position }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">项目：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.project }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">合同期限：</span>
              <span class="normalFont">{{ parseTime(posOfferExtension.positionOfferExtension.contractPeriodStart) }}至{{ posOfferExtension.positionOfferExtension.contractPeriodEnd }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">试用期：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.probationPeriod }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">试用期日薪：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.probationPeriodMonthSalary, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">其他拟约定：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.otherRamks }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div v-if="!isDown">
        <div class="title">报道信息</div>
        <div class="review">
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">时间：</span>
              <span class="normalFont">{{ parseTime(posOfferExtension.positionOfferExtension.reportDate) }} {{ toFixTime(posOfferExtension.positionOfferExtension.reportTime, 0, 5) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">地址：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.reportLocation }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">报到接待人：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.reportReceptionist }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">接待人电话：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.reportReceptionistPhone }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div v-if="!isDown && !isFreelance">
        <div class="title">薪资明细</div>
        <div class="review">
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">城市：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.city }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="24">
              <span class="boldFont">姓名：</span>
              <span class="normalFont">{{ posOfferExtension.positionOfferExtension.name }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">社保基数：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.socialInsuranceBase, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">公积金基数：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.providentFundBase, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">十三薪基数：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.baseSalary, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">年/病假天数：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.leaveDays, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">基本薪：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.thirteenSalaryBase, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">岗位/补贴：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.totalSubsidy, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">社保成本：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.socialInsuranceCostCompany, 2) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">公积金成本：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.providentFundCostCompany, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">十三薪：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.thirteenSalary, 2) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">员工关怀：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.employeeCare, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">差旅/交通：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.transportation, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">社保/档案：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.archives, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">体检/背调：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.otherBase, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">年假：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.annualLeave, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">其他：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.other, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">月开支：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.monthCost, 2) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">月外包费：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.monthOutsourcing, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">日外包费：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.dayOutsourcing, 0) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">时外包费：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.hourOutsourcing, 0) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">增值税：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.taxRate, 2) }}</span>
            </van-col>
          </van-row>
          <van-row class="vanRowStyle" type="flex" justify="space-around">
            <van-col span="12">
              <span class="boldFont">收入：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.income, 2) }}</span>
            </van-col>
            <van-col span="12">
              <span class="boldFont">利润率(%)：</span>
              <span class="normalFont">{{ toFix(posOfferExtension.positionOfferExtension.profitMargin, 2) }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="bottom-Site"></div>
      <div class="bottom">
        <div class="buttomButton cancleButton" @click="leftReturn">取消</div>
        <div class="buttomButton saveButton" @click="handleSave">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {NavBar, Search, DropdownMenu, DropdownItem, Button, Popup, Picker, Row, Col} from 'vant';
import bhNavBar from "@/components/bright/bh-navBar/navBar";
import {responseUtil} from "@/libs/brightUtil";
import {
  calculationOfferExtension,
  getBaseInfo,
  initOfferExtension,
  queryEnclosure,
  saveOfferExtension,
  calculationOfferExtensionFreelance
} from "@/getData/getData";
import {checkToken, localStorageGet, parseTime} from "@/libs/util";
import { DICTIONARY } from "@/getData/mutation-types";

export default {
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Row.name]: Row,
    [Col.name]: Col,
    bhNavBar
  },
  name: "approveDetails",
  data() {
    return {
      id: '',
      editType: 0,
      isDown: '',
      isFreelance: '',
      companyTypeOptions: [],
      posOfferExtension: {
        positionOfferExtension: {
          extensionId: '',
          inputOutsourcingType: '月报价',
          inputBusinessExpenses: '',
          inputOutsourcing: '',
          candidateId: '',
          personnelId: '',
          isDown: '',
          isFreelance: '',
        },
      },
      personnel: {},
      fileList: []
    }
  },
  created() {
    const dic = localStorageGet(DICTIONARY)
    this.companyTypeOptions = dic['049']
  },
  mounted() {
    if(!checkToken()){
      this.$router.replace({ name: 'login' })
    }
    this.id = this.$route.query.id
    this.posOfferExtension.positionOfferExtension.extensionId = this.id
    this.getDetails()
  },
  methods: {
    getDetails() {
      var that = this
      initOfferExtension(this.posOfferExtension).then(function (res) {
        responseUtil.dealResponse(that, res, () => {
          that.posOfferExtension = res.data
          that.isDown = that.posOfferExtension.positionOfferExtension.isDown
          that.isFreelance = that.posOfferExtension.positionOfferExtension.isFreelance
          that.getFile()
          that.getPersonnel()
        })
      })
    },
    // 查询文件方法
    getFile: function () {
      var that = this
      var params = {}
      params.type = '5'
      params.id = this.posOfferExtension.positionOfferExtension.candidateId
      queryEnclosure(params).then(function (res) {
        responseUtil.dealResponse(that, res, () => {
          that.fileList = res.data
        })
      })
    },
    // 数值计算方法
    handleChange(event, types) {
      var that = this
      this.posOfferExtension.calculationTypes = types
      this.posOfferExtension.positionOfferExtension.isDown = this.isDown
      this.posOfferExtension.positionOfferExtension.isFreelance = this.isFreelance
      calculationOfferExtension(this.posOfferExtension).then(res => {
        responseUtil.dealResponse(that, res, () => {
          this.posOfferExtension = res.data
        })
      })
    },
    handleDayBaseChange(event, types) {
      var that = this
      this.posOfferExtension.positionOfferExtension.baseSalary = this.posOfferExtension.positionOfferExtension.thirteenSalaryBase
      this.posOfferExtension.calculationTypes = types
      this.posOfferExtension.positionOfferExtension.isDown = this.isDown
      this.posOfferExtension.positionOfferExtension.isFreelance = this.isFreelance
      this.posOfferExtension.positionOfferExtension.isAffiliated = this.isAffiliated
        calculationOfferExtensionFreelance(this.posOfferExtension).then(res => {
          responseUtil.dealResponse(that, res, () => {
            this.posOfferExtension = res.data
          })
        })
    },
    getPersonnel() {
      var that = this
      const id = this.posOfferExtension.positionOfferExtension.personnelId
      getBaseInfo(id).then(function (res) {
        responseUtil.dealResponse(that, res, () => {
          that.personnel = res.data
        })
      })
    },
    // 保存方法
    handleSave() {
      const that = this
      that.posOfferExtension.positionOfferExtension.isDown = this.isDown
      that.posOfferExtension.positionOfferExtension.isFreelance = this.isFreelance
      if(!this.isFreelance){
        // 重新计算
        that.posOfferExtension.calculationTypes = [4, 5, 6, 8, 9]
        calculationOfferExtension(that.posOfferExtension).then(res => {
          responseUtil.dealResponse(that, res, () => {
            this.posOfferExtension = res.data
            this.saveOfferExtension()
          })
        })
      } else {
        // Freelance 重新计算
        that.posOfferExtension.calculationTypes = [2, 3]
        calculationOfferExtensionFreelance(this.posOfferExtension).then(res => {
          responseUtil.dealResponse(that, res, () => {
            this.posOfferExtension = res.data
            this.saveOfferExtension()
          })
        })
      }
    },
    saveOfferExtension(){
      var that = this
      saveOfferExtension(that.posOfferExtension).then(res => {
        responseUtil.dealResponse(that, res, () => {
          responseUtil.alertOK('保存成功')
          this.leftReturn()
        })
      })
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    getCompany() {
      if (this.companyTypeOptions && this.posOfferExtension.positionOfferExtension.company) {
        const item = this.companyTypeOptions.find((item) => item.code === this.posOfferExtension.positionOfferExtension.company)
        if (item) {
          return item.label
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    toFixTime(time, start, end) {
      if (time) {
        return time.slice(start, end)
      } else {
        return ''
      }
    },
    parseTime(val) {
      return val ? parseTime(val, '{y}年{m}月{d}日') : ''
    },
    toFix(num1, num2) {
      if (!num1) {
        return 0
      } else {
        return Number(num1).toFixed(num2)
      }
    }
  }
}
</script>

<style lang="less" scoped>
#approveDetails {
  .title {
    font-size: 12px;
    margin: 15px 15px 5px 15px;
    color: #6D00FB;
    font-weight: bold;
    text-align: left;
    font-family: PingFangSC-Semibold;
  }

  .boldFont {
    font-size: 14px;
    font-weight: bold;
  }

  .normalFont {
    font-size: 13px;
    font-weight: normal;
    color: #999;
  }

  .review {
    margin: 0 15px 15px 15px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }

  .vanRowStyle {
    width: 95%;
    margin: auto;
  }
  .bottom-Site{
    width: 100%;
    height: 60px;
  }
  .bottom {
    width: 100%;
    height: 50px;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 0px;
    background-color: rgba(248, 248, 248, 1);
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
  }
  .saveButton {
    color: white;
    border: 1px solid #6D00FB;
    background: linear-gradient(90deg, #1500FB 0%, #5400FB 47%, #6D00FB 99%);
  }
  .cancleButton {
    color: black;
    border: 1px solid black;
  }
  .buttomButton {
    width: 60px;
    height: 32px;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    margin-right: 15px;
    border-radius: 8px;
  }
}
</style>